import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import GoogleTagManager from './GoogleTagManager';

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

const CenteredTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;
`;

const progressAnimation = keyframes`
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
`;

const ProgressBarContainer = styled.div`
  width: 300px;
  height: 4px;
  background-color: #f0f0f0;
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  width: 50%;
  height: 100%;
  background-color: #00c300;
  position: relative;
  animation: ${progressAnimation} 1.5s infinite linear;
`;
const SERVICE_MAP = {
  'realestate_agent': 'ra',
  'realestate_client': 'rc',
  'realestate_tenant': 'rt',
  'realestate_benefits': 'rb',
  'recruit_agent': 'raj',
  'recruit_client': 'rcj',
};

const BASE_URL = {
  'ra':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=lNrLll&liff_id=2000515070-gr8Ne6Kv',
  'rc':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=Eksu9K&liff_id=2000515070-gr8Ne6Kv',
  'rt':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=yhhxdI&liff_id=2000515070-gr8Ne6Kv',
  'rb':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=wUAILq&liff_id=2000515070-gr8Ne6Kv',
  'raj':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=uwwYbS&liff_id=2000515070-gr8Ne6Kv',
  'rcj':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=MNJXlX&liff_id=2000515070-gr8Ne6Kv',
};

const ERROR_URL = {
  'ra':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=j0Vh1j&liff_id=2000515070-gr8Ne6Kv',
  'rc':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=akDkGf&liff_id=2000515070-gr8Ne6Kv',
  'rt':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=bDwZuT&liff_id=2000515070-gr8Ne6Kv',
  'rb':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=Llm9PP&liff_id=2000515070-gr8Ne6Kv',
  'raj':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=qmmaAa&liff_id=2000515070-gr8Ne6Kv',
  'rcj':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=dBW3EZ&liff_id=2000515070-gr8Ne6Kv',
};

const UNKNOWNN_MEDIA_MAP = {
  'ra': {
    'ig': 'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=FtlrOx&liff_id=2000515070-gr8Ne6Kv',
    'glis': 'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=c5E4x2&liff_id=2000515070-gr8Ne6Kv',
    'gdem':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=LlJ5sl&liff_id=2000515070-gr8Ne6Kv'    
  },
  'raj': {
    'ig':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=7KYSOt&liff_id=2000515070-gr8Ne6Kv',
    'glis':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=uKjEqb&liff_id=2000515070-gr8Ne6Kv',
  },
  'rcj':{
    'ig':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=G3Kgup&liff_id=2000515070-gr8Ne6Kv',
    'glis':'https://liff.line.me/2000515070-gr8Ne6Kv/landing?follow=%40702ficiu&lp=bXKYO6&liff_id=2000515070-gr8Ne6Kv'
  }
  // 他のサービスとプラットフォームの組み合わせも同様に追加
};

const DEFAULT_URL = 'https://st.rakusumu.jp/servicelist';

function App() {
  const [status, setStatus] = useState('loading'); // 'loading', 'redirecting', 'error'

  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const pathSegments = urlObject.pathname.split('/').filter(segment => segment);
  console.log(pathSegments)
  const pageName = pathSegments[0] || '';
  const media = pathSegments[1] || '';
  
  console.log('pageName"',pageName)
  const urlParams = new URLSearchParams(window.location.search);
  let id = urlParams.get('utm_content') || '';
  let service = SERVICE_MAP[pageName] || '';

  console.log('ID:', id);
  console.log('Service:', service);

  useEffect(() => {
    const fetchData = async () => {
    const redirectWithDelay = (url) => {
        setStatus('redirecting');
        setTimeout(() => {
          window.location.href = url;
        }, 1); // 1秒の遅a延
      }; 
      if (!service) {
        redirectWithDelay(DEFAULT_URL);
        return;
      }

      if (!id) {
        if (media && service && UNKNOWNN_MEDIA_MAP[service] && UNKNOWNN_MEDIA_MAP[service][media]) {
          setStatus('redirecting');
          redirectWithDelay(UNKNOWNN_MEDIA_MAP[service][media]);
        } else if (BASE_URL[service]) {
          setStatus('redirecting');
          redirectWithDelay(BASE_URL[service]);
        } else if (ERROR_URL[service]) {
          setStatus('redirecting');
          redirectWithDelay(ERROR_URL[service]);
        } else {
          setStatus('redirecting');
          redirectWithDelay(DEFAULT_URL);
        }
        return;
      }

      // id がある場合、API を呼び出す
      const apiUrl = `https://redirect.microcms.io/api/v1/map?filters=utm_service[equals]${service}[and]utm_content[equals]${id}`;
      console.log('API URL:', apiUrl);
      
      try {
        const response = await fetch(apiUrl, {
          headers: {
            'X-MICROCMS-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        const data = await response.json();
        console.log('API Response:', data);
        
        if (data['contents'] && data['contents'][0] && data['contents'][0]['redirect_url']) {
          setStatus('redirecting');
          redirectWithDelay(data['contents'][0]['redirect_url']);
        }else if (media && service && UNKNOWNN_MEDIA_MAP[service] && UNKNOWNN_MEDIA_MAP[service][media]) {
            setStatus('redirecting');
            redirectWithDelay(UNKNOWNN_MEDIA_MAP[service][media]);
        } else if (ERROR_URL[service]) {
          setStatus('redirecting');
          redirectWithDelay(ERROR_URL[service]);
        } else {
          setStatus('redirecting');
          redirectWithDelay(DEFAULT_URL);
        }
      } catch (error) {
        console.error('APIの呼び出しエラー:', error);
        if (media && service && UNKNOWNN_MEDIA_MAP[service] && UNKNOWNN_MEDIA_MAP[service][media]) {
        setStatus('redirecting');
        redirectWithDelay(UNKNOWNN_MEDIA_MAP[service][media]);
        }else if (ERROR_URL[service]) {
          setStatus('redirecting');
          redirectWithDelay(ERROR_URL[service]);
        } else {
          setStatus('redirecting');
          redirectWithDelay(DEFAULT_URL);
        }
      }
    };
    
    fetchData();
  }, [service, id,media]);

  if (status === 'loading' || status === 'redirecting') {
    return (
      <CenteredContainer>
        <CenteredTitle>LINEを開いています。<br/>このままお待ちください。</CenteredTitle>
        <ProgressBarContainer>
          <ProgressBar />
        </ProgressBarContainer>
      </CenteredContainer>
    );
  }

  return null; // この行に到達することはないはずですが、念のため
}

export default App;